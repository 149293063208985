<template>
  <div>
    <div class="video" ref="playBox">
      <!-- 视频数据站位 -->
      <div id="playWnd" class="playWnd"></div>
    </div>
  </div>
</template>

<script>

let oWebControl = null
let WebControl = null
//声明公用变量
var initCount = 0;
var pubKey = '';
const injectScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.src = src
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.head.appendChild(script)
  })
}
import $ from 'jquery'
export default {
  name: 'baseCamera',
  data () {
    return {
      collapsed: false,
      thirdpartylist: [],
      permissionlist: [],
      warningListDataSource: []

    };
  },
  props: {
    appkey: {
      type: String
    },
    secret: {
      type: String
    },
    ip: {
      type: String
    },
    port: {
      type: Number
    },
    // 预览回放(0,1)
    playMode: {
      type: Number,
      default: 0
    },
    // 指定布局
    layout: {
      type: String,
      default: '1x1'
    },
    // 自定义工具条按钮
    buttonIDs: {
      type: String,
      default: '0,16,256,257,258,259,260,512,513,514,515,516,517,768,769'
    }
  },
  created () {
    if (oWebControl) {
      oWebControl.JS_Disconnect().then(function () {//oWebControl为WebControl的对象//断开与插件服务的连接成功
      }, function () {//断开与插件服务的连接失败
      });
    }

  },
  mounted () {
    let that = this
    injectScript('https://mainimg.terabits.cn/jsWebControl-1.0.0.min.js').then(() => {
      WebControl = window.WebControl
      //加载后要做的事情
      that.initPlugin()
    })
  },
  methods: {
    // 创建播放实例
    // 创建WebControl实例与启动插件
    initPlugin (type) {
      let width = this.$refs.playBox.clientWidth
      let height = this.$refs.playBox.clientHeight
      let that = this
      oWebControl = new WebControl({
        szPluginContainer: "playWnd",                       //指定容器id
        iServicePortStart: 15900,                           //指定起止端口号，建议使用该值
        iServicePortEnd: 15909,
        cbConnectSuccess: function () {
          // setCallbacks();
          //实例创建成功后需要启动服务
          oWebControl.JS_StartService("window", {
            dllPath: "./VideoPluginConnect.dll"
          }).then(function () {
            oWebControl.JS_CreateWnd("playWnd", width, height).then(function () {         //JS_CreateWnd创建视频播放窗口，宽高可设定
              // console.log("JS_CreateWnd success");
              that.init(type);                                 //创建播放实例成功后初始化
            });
          }, function () {

          });
        },
        cbConnectError: function () {
          // console.log("cbConnectError");
          oWebControl = null;
          $("#playWnd").html("插件未启动，正在尝试启动，请稍候...");
          WebControl.JS_WakeUp("VideoWebPlugin://");        //程序未启动时执行error函数，采用wakeup来启动程序
          initCount++;
          if (initCount < 3) {
            setTimeout(function () {
              this.initPlugin();
            }, 3000)
          } else {
            $("#playWnd").html("插件启动失败，请检查插件是否安装！");
          }
        },
        cbConnectClose: function () {
          // console.log("cbConnectClose");
          oWebControl = null;
        }
      });

    },

    //初始化
    init () {
      let width = this.$refs.playBox.clientWidth
      let height = this.$refs.playBox.clientHeight
      this.getPubKey(() => {

        var appkey = this.appkey;                           //综合安防管理平台提供的appkey，必填
        var secret = this.setEncrypt(this.secret);   //综合安防管理平台提供的secret，必填
        var ip = this.ip;                           //综合安防管理平台IP地址，必填
        var port = this.port;
        var playMode = this.playMode;                                  //初始播放模式：0-预览，1-回放
        var snapDir = "D:\\SnapDir";                       //抓图存储路径
        var videoDir = "D:\\VideoDir";                     //紧急录像或录像剪辑存储路径
        var layout = this.layout;                                //playMode指定模式的布局
        var enableHTTPS = 1;                               //是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
        var encryptedFields = 'secret';					   //加密字段，默认加密领域为secret
        var showToolbar = 1;                               //是否显示工具栏，0-不显示，非0-显示
        var showSmart = 0;                                 //是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
        var buttonIDs = this.buttonIDs;  //自定义工具条按钮
        //var reconnectTimes = 2;                            // 重连次数，回放异常情况下有效
        //var reconnectTime = 4;                             // 每次重连的重连间隔 >= reconnectTime
        ////////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

        oWebControl.JS_RequestInterface({
          funcName: "init",
          argument: JSON.stringify({
            appkey: appkey,                           //综合安防管理平台提供的appkey，必填
            secret: secret,   //综合安防管理平台提供的secret，必填
            ip: ip,                           //综合安防管理平台IP地址，必填
            //API网关IP地址
            playMode: playMode,                        //播放模式（决定显示预览还是回放界面）
            port: port,                                //端口
            snapDir: snapDir,                          //抓图存储路径
            videoDir: videoDir,                        //紧急录像或录像剪辑存储路径
            layout: layout,                            //布局
            enableHTTPS: enableHTTPS,                  //是否启用HTTPS协议
            encryptedFields: encryptedFields,          //加密字段
            showToolbar: showToolbar,                  //是否显示工具栏
            showSmart: showSmart,                      //是否显示智能信息
            buttonIDs: buttonIDs                       //自定义工具条按钮
            //reconnectTimes：reconnectTimes,            //重连次数
            //reconnectDuration：reconnectTime           //重连间隔
          })
        }).then(function () {
          oWebControl.JS_Resize(width, height);  // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题

        });
      });
    },
    // 获取公钥
    getPubKey (callback) {
      oWebControl
        .JS_RequestInterface({
          funcName: 'getRSAPubKey',
          argument: JSON.stringify({
            keyLength: 1024
          })
        })
        .then(function (oData) {
          // console.log(oData)
          if (oData.responseMsg.data) {
            pubKey = oData.responseMsg.data
            callback()
          }
        })
    },
    // RSA 加密
    setEncrypt (value) {
      var encrypt = new this.$JSEncrypt()
      encrypt.setPublicKey(pubKey)
      return encrypt.encrypt(value)
    },
    // 回调的消息
    cbIntegrationCallBack (oData) {
      let { responseMsg: type, responseMsg: msg } = oData

      if (type === 'error') {
        console.log(type, msg, this.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss'))
      } else {
        console.log(type, msg, this.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss'))
      }
    },
    // 预览
    startPreview (cameraCode) {
      // 点击查询后显示
      oWebControl.JS_ShowWnd()
      var cameraIndexCode = cameraCode //获取输入的监控点编号值，必填
      // console.log(cameraCode)
      var streamMode = 0 //主子码流标识：0-主码流，1-子码流
      var transMode = 1 //传输协议：0-UDP，1-TCP
      var gpuMode = 0 //是否启用GPU硬解，0-不启用，1-启用
      var wndId = -1 //播放窗口序号（在2x2以上布局下可指定播放窗口）

      cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, '')
      cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, '')

      oWebControl.JS_RequestInterface({
        funcName: 'startPreview',
        argument: JSON.stringify({
          cameraIndexCode: cameraIndexCode, //监控点编号
          streamMode: streamMode, //主子码流标识
          transMode: transMode, //传输协议
          gpuMode: gpuMode, //是否开启GPU硬解
          wndId: wndId //可指定播放窗口
        })
      })
    },
    // 回放
    startPlayBack (cameraIndexCode, startTimeStamp, endTimeStamp) {

      // var startTimeStamp = startTimeStamp
      // var endTimeStamp = endTimeStamp
      // console.log(moment(startTimeStamp).format(), endTimeStamp)
      var recordLocation = 1;                                     //录像存储位置：0-中心存储，1-设备存储
      var transMode = 1;                                          //传输协议：0-UDP，1-TCP
      var gpuMode = 0;                                            //是否启用GPU硬解，0-不启用，1-启用
      var wndId = -1;                                             //播放窗口序号（在2x2以上布局下可指定播放窗口）
      // console.log(this.state.begintime)
      // console.log(this.state.endtime)
      oWebControl.JS_RequestInterface({
        funcName: "startPlayback",
        argument: JSON.stringify({
          cameraIndexCode: cameraIndexCode,                   //监控点编号
          startTimeStamp: Math.floor(startTimeStamp / 1000).toString(),  //录像查询开始时间戳，单位：秒
          endTimeStamp: Math.floor(endTimeStamp / 1000).toString(),      //录像结束开始时间戳，单位：秒
          recordLocation: recordLocation,                     //录像存储类型：0-中心存储，1-设备存储
          transMode: transMode,                               //传输协议：0-UDP，1-TCP
          gpuMode: gpuMode,                                   //是否启用GPU硬解，0-不启用，1-启用
          wndId: wndId                                         //可指定播放窗口
        })
      })
    },
    // 停止全部预览
    stopAllPreview () {
      oWebControl.JS_RequestInterface({
        funcName: 'stopAllPreview'
      })
    },

    // 格式化时间
    dateFormat (oDate, fmt) {
      var o = {
        "M+": oDate.getMonth() + 1, //月份
        "d+": oDate.getDate(), //日
        "h+": oDate.getHours(), //小时
        "m+": oDate.getMinutes(), //分
        "s+": oDate.getSeconds(), //秒
        "q+": Math.floor((oDate.getMonth() + 3) / 3), //季度
        "S": oDate.getMilliseconds()//毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (oDate.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
      }
      return fmt;
    },

    destroy () {
      oWebControl.JS_DestroyWnd().then(function () { // oWebControl 为 WebControl 的对象
        // 销毁插件窗口成功
      }, function () {
        // 销毁插件窗口失败
      })
    },
    hide () {
      oWebControl.JS_CuttingPartWindow(0, 0, 10000, 360)
    },
    show () {
      oWebControl.JS_RepairPartWindow(0, 0, 10000, 360)
    }
  },
  destroyed () { // 组件销毁后
    if (oWebControl != null) {
      oWebControl.JS_HideWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      oWebControl.JS_RequestInterface({ funcName: "destroyWnd" }) // 销毁当前播放的视频
      oWebControl.JS_Disconnect() // 断开与插件服务连接
    }
  }

};
</script>

<style scoped lang="scss">
.video {
  margin: 0 auto;
  width: 100%;
  height: 72vh;
  .playWnd {
    width: 100%;
    height: 100%;
  }
}
</style>
