import { login, logins, singleLogin } from '@/api/index'
import { getToken, setToken, removeToken } from '@/util/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    }
  },

  actions: {
    // // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          setToken(res.headers.authorization)
          commit('SET_TOKEN', res.headers.authorization)
          commit('SET_NAME', res.data.data.name)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 浙政钉登录
    Logins ({ commit }, token) {
      return new Promise((resolve, reject) => {
        logins(token).then(res => {
          setToken(res.data.access_token)
          commit('SET_TOKEN', res.data.access_token)
          commit('SET_NAME', res.data.data.name)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 单点登录
    SingleLogin ({ commit },) {

      return new Promise((resolve, reject) => {
        singleLogin().then(res => {
          // console.log(res)
          setToken(window.location.href.split('=')[1])
          commit('SET_TOKEN', window.location.href.split('=')[1])
          commit('SET_NAME', res.data.data.name)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // // 退出系统
    // LogOut ({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     logout(state.token).then(() => {
    //       commit('SET_TOKEN', '')
    //       commit('SET_ROLES', [])
    //       commit('SET_PERMISSIONS', [])
    //       removeToken()
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
