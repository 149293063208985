// import { constantRoutes } from '@/router'
// import { getRouters } from '@/api/index'
// import Layout from '@/views/Layout'
// import ParentView from '@/components/ParentView';
// import InnerLink from '@/layout/components/InnerLink'

const permission = {
  state: {
    routers: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTES: (state, routers) => {
      state.addRoutes = routers
      // state.routers = constantRoutes.concat(routers)
    },
    // SET_DEFAULT_ROUTES: (state, routes) => {
    //   state.defaultRoutes = constantRoutes.concat(routes)
    // },
    // SET_TOPBAR_ROUTES: (state, routes) => {
    //   // 顶部导航菜单默认添加统计报表栏指向首页
    //   const index = [{
    //     path: 'index',
    //     meta: { title: '统计报表', icon: 'dashboard'}
    //   }]
    //   state.topbarRouters = routes.concat(index);
    // },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes
    },
  },
  actions: {
    // 生成路由
    // GenerateRoutes ({ commit }) {
    //   return new Promise(resolve => {
    //     // 向后端请求路由数据
    //     getRouters().then(res => {
    //       // res.data.map(el => {

    //       // })
    //       // console.log(res, 11111)
    //       const sdata = JSON.parse(JSON.stringify(res.data))
    //       // console.log(sdata)
    //       // sdata.push({
    //       //   hidden: false,
    //       //   meta: {
    //       //     icon: "icon-shuizhi",
    //       //     noCache: true,
    //       //     title: "健康申报"
    //       //   },
    //       //   name: 'HealthDeclare',
    //       //   path: '/healthdeclare',
    //       //   component: 'Health/index'
    //       // })

    //       // const rdata = JSON.parse(JSON.stringify(res.data))
    //       let sidebarRoutes = filterAsyncRouter(sdata)
    //       // console.log(sidebarRoutes)
    //       // let rewriteRoutes = filterAsyncRouter(rdata, false, true)
    //       // rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
    //       sidebarRoutes = [{
    //         path: '',
    //         hidden: true,
    //         component: Layout,
    //         children: sidebarRoutes
    //       }]
    //       // console.log(sidebarRoutes, 111)
    //       commit('SET_ROUTES', sidebarRoutes)
    //       // console.log(rewriteRoutes, 111)
    //       // commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes))
    //       // console.log(sidebarRoutes)
    //       // commit('SET_DEFAULT_ROUTES', sidebarRoutes)
    //       // commit('SET_TOPBAR_ROUTES', sidebarRoutes)
    //       resolve(sidebarRoutes)
    //     })
    //   })
    // }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
// function filterAsyncRouter (asyncRouterMap) {
//   // console.log(asyncRouterMap, 2222)
//   let routers = asyncRouterMap.filter(route => {
//     if (route.component) {
//       // Layout ParentView 组件特殊处理
//       if (route.component === 'Layout') {
//         route.component = Layout
//       } else {
//         route.component = loadView(route.component)
//       }
//     }
//     if (route.children != null && route.children && route.children.length) {
//       route.children = filterAsyncRouter(route.children)
//     }
//     return true
//   })
//   // console.log(routers, 11)
//   // routers = [{
//   //   path: '',
//   //   hidden: true,
//   //   component: Layout,
//   //   children: routers
//   // }]
//   return routers
//   // console.log(routers)
// }

// function filterChildren (childrenMap, lastRouter = false) {
//   var children = []
//   childrenMap.forEach((el) => {
//     if (el.children && el.children.length) {
//       if (el.component === 'ParentView' && !lastRouter) {
//         el.children.forEach(c => {
//           c.path = el.path + '/' + c.path
//           if (c.children && c.children.length) {
//             children = children.concat(filterChildren(c.children, c))
//             return
//           }
//           children.push(c)
//         })
//         return
//       }
//     }
//     if (lastRouter) {
//       el.path = lastRouter.path + '/' + el.path
//     }
//     children = children.concat(el)
//   })
//   return children
// }

// export const loadView = (view) => {
//   // if (process.env.NODE_ENV === 'development') {
//   return (resolve) => require([`@/views/${view}`], resolve)
//   // } else {
//   // 使用 import 实现生产环境的路由懒加载
//   // return () => import(`@/views/${view}`)
//   // }
// }

export default permission
