let url = window.location.host
// import store from '../store'
// let url = 'wzwj.terabits.cn'
// let url = 'scdisinfection.terabits.cn'
let name
let baseURL
let acronym
let codeLogin
// qywj.terabits.cn
// scdisinfection.terabits.cn
// sxwj.terabits.cn
// yjwj.terabits.cn
// wzwj.terabits.cn
switch (url) {
  case 'qywj.terabits.cn':
    name = '庆元县'
    acronym = 'qy'
    baseURL = 'https://qywj.terabits.cn:9016'
    codeLogin = true
    break;
  case 'scdisinfection.terabits.cn':
    name = '上城区'
    acronym = 'sc'
    baseURL = 'https://scdisinfection.terabits.cn:9091'
    codeLogin = false
    break;
  case 'sxwj.terabits.cn':
    name = '绍兴市'
    acronym = 'sx'
    baseURL = 'https://sxwj.terabits.cn:9015'
    codeLogin = false
    break;
  case 'yjwj.terabits.cn':
    name = '永嘉县'
    acronym = 'yj'
    baseURL = 'https://yjwj.terabits.cn:9018'
    codeLogin = true
    break;
  case 'wzwj.terabits.cn':
    name = '温州市'
    acronym = 'wz'
    baseURL = 'https://wzwj.terabits.cn:9017'
    codeLogin = true
    break;
}
// store.dispatch("getDomain", { baseURL, name, url })

export {
  url,
  name,
  baseURL,
  acronym,
  codeLogin
}
