import { getDeviceLocation, getSceneList, getAlgorithmList } from '@/api/index'
// import { name, url, baseURL } from '../../util/config'
// console.log(name, url, baseURL)
const common = {
  state: {
    locationList: [],
    sceneList: [],
    algorithmList: [],
    domainName: '',
    baseUrl: '',
    url: '',
    acronym: '',
    codeLogin: false
  },

  mutations: {
    SET_Location: (state, val) => {
      state.locationList = val
    },
    SET_SCENE: (state, val) => {
      state.sceneList = val
    },
    SET_ALGORITHM: (state, val) => {
      state.algorithmList = val
    },
    SET_DOMAIN: (state, val) => {
      state.domainName = val.name
      state.baseUrl = val.baseURL
      state.url = val.url
      state.acronym = val.acronym
      state.codeLogin = val.codeLogin
    }
  },

  actions: {
    // 设备位置
    getLocation ({ commit }) {
      return new Promise((resolve, reject) => {
        getDeviceLocation().then(res => {
          commit('SET_Location', res.data.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 场景列表
    getScene ({ commit }) {
      return new Promise((resolve, reject) => {
        getSceneList().then(res => {
          commit('SET_SCENE', res.data.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 场景算法列表
    getAlgorithm ({ commit }, name) {
      return new Promise((resolve, reject) => {
        getAlgorithmList(name).then(res => {
          commit('SET_ALGORITHM', res.data.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 根据域名判断哪个平台
    getDomain ({ commit }, data) {
      commit('SET_DOMAIN', data)
    }
  }
}

export default common
