import request from '@/util/request'
// 登录
const login = (params) => request.post(`/auth/login/username?password=${params.password}&username=${params.username}`)

// 单点登录获取信息接口
const singleLogin = () => request.post('/auth/currentUser')
// 浙政钉登录
const logins = (token) => request.get(`/api/security/exchangeUserInfo?token=${token}`)

/*  通用接口  */
// 设备位置
const getDeviceLocation = () => request.get("/directory/names")
// 场景列表
const getSceneList = () => request.get('/scene')
// 场景算法
const getAlgorithmList = (name) => request.get(`${name ? '/algorithm?name=' + name : '/algorithm'}`)
// 省市区级联查询
const getRegion = (adcode) => request.get(`/area/allRegion?adcode=${adcode}`)
// 获取单位名列表
const getSiteNameList = () => request.get('/site/names')


/* 基本信息 */
//各场景单位数量
const listSceneSiteSum = () => request.get("/statistics/listSceneSiteSum")
//网点名列表在线设备数量
const onlineList = () => request.get("/statistics/listNamesAndOnlineSum")
//网点列表
// 修改一下
const sitelist = () => request.get("/site")

/* 图像识别 */
// 行为分析列表/动态分析列表
const analyseList = (params) => request.get(`/detection/vison${params}`)
// 预检分诊列表
const getPreList = () => request.get('/detection/inspection/list')
// 行为分析详情
const behaviorDetail = (params) => request.get(`/detection/getalarmDetails${params}`)
const imageExist = (id) => request.get(`/detection/imageExist?id=${id}`)
/* 视频看板 */
// 视频看板列表
const getVideoList = (iscid) => request.get(`/isc/completeDeviceTree?iscid=${iscid}`)

/* 设备管理 */
// 获取设备列表
const getDeviceList = (params) => request.get(`/camera${params}`)
// 参数设置获取单个基本信息
const getArguments = (id) => request.get(`/camera/findById?id=${id}`)
// 参数设置图片
const getArgumentsImage = (id) => request.get(`/camera/cover?id=${id}`)
// 修改参数设置
const editArguments = (params) => request.put(`/camera${params}`)
// 获取isc平台列表
const getIscList = () => request.get('/isc')
// 获取单个iSC平台下的酒店列表
const getHotel = (iscid) => request.get(`/isc/area?iscid=${iscid}`)
// 获取酒店下设备列表
const getUnitDevice = (params) => request.get(`/isc/device${params}`)
// 添加设备
const addDevice = (params) => request.post(`/camera${params}`)
// 删除设备
const deleteDevice = (cameraId) => request.delete(`/camera/${cameraId}`)

/* 单位管理 */
// 单位列表
const getUnitList = (params) => request.get(`/site${params}`)
// 添加单位
const addUnit = (params) => request.post(`/site${params}`)
// 删除单位
const deleteUnit = (siteId) => request.delete(`/site/${siteId}`)
// 修改单位
const editUnit = (params) => request.put(`/site${params}`)

/* 房间管理 */
// 房间列表
const getRoomList = (params) => request.get(`/room${params}`)
// 添加房间
const addRoom = (params) => request.post(`/room${params}`)
// 删除房间
const deleteRoom = (roomId) => request.delete(`/room/${roomId}`)
// 修改房间
const editRoom = (params) => request.put(`/room${params}`)
// 监测状态修改
const editType = (roomId) => request.patch(`/room/status?roomId=${roomId}`)
// 查询房间参数
const getRoomArgs = (roomId) => request.get(`/args/${roomId}`)
// 修改房间参数
const editRoomArgs = (params) => request.post(`/args${params}`)

/* 用户管理 */
// 用户列表
const getUserList = (params) => request.get(`/admin${params}`)
// 获取用户类型
const getUserType = () => request.get('/role')
// 添加用户
const addUser = (params) => request.post(`/admin${params}`)
// 删除用户
const deleteUser = (adminId) => request.delete(`/admin/${adminId}`)

/* 算法管理 */
// 添加算法
const addAlgorithm = (params) => request.post(`/algorithm/insert${params}`)

export {
  getDeviceLocation,
  getSceneList,
  getAlgorithmList,
  getRegion,
  getSiteNameList,
  login,
  listSceneSiteSum,
  onlineList,
  sitelist,
  analyseList,
  behaviorDetail,
  getVideoList,
  getDeviceList,
  getArguments,
  getArgumentsImage,
  editArguments,
  getIscList,
  getUnitDevice,
  getHotel,
  addDevice,
  deleteDevice,
  getUnitList,
  addUnit,
  deleteUnit,
  editUnit,
  getRoomList,
  editRoom,
  addRoom,
  deleteRoom,
  getRoomArgs,
  editType,
  editRoomArgs,
  getUserList,
  addUser,
  getUserType,
  deleteUser,
  addAlgorithm,
  logins,
  imageExist,
  singleLogin,
  getPreList
}
