<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {

    };
  },
  created () {

  },
  computed: {
    ...mapGetters(['domainName'])
  },
  mounted () {
    document.title = this.domainName === '庆元县' ? '庆元卫监监控分析平台' : `${this.domainName}卫健行政执法视频监控智能图像分析平台`
  },
  methods: {

  }
};
</script>

<style lang="scss">
</style>
