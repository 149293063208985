const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  locationList: state => state.common.locationList,
  sceneList: state => state.common.sceneList,
  algorithmList: state => state.common.algorithmList,
  domainName: state => state.common.domainName,
  baseUrl: state => state.common.baseUrl,
  url: state => state.common.url,
  acronym: state => state.common.acronym,
  codeLogin: state => state.common.codeLogin
  // permission_routes: state => state.permission.routes,
  // routers: state => state.permission.routers,
}
export default getters
