import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './util/pxtorem'
import ElementUI from 'element-ui';
import vuescroll from 'vuescroll';
import 'element-ui/lib/theme-chalk/index.css';
import './style/main.scss'
import './permission'
import 'lib-flexible/flexible'
// 分页组件
import Pagination from "@/components/Pagination";
import moment from 'moment'
import * as echarts from 'echarts';
import 'echarts-liquidfill';
import { url, name, baseURL, acronym, codeLogin } from './util/config'
import * as serviceWorker from './util/serviceWorker';
import Components from './components/index'

// import './util/config'
import { validator, tools } from './util/validator'
import JSEncrypt from 'jsencrypt'
import { Base64 } from 'js-base64'
// import TbCommon from 'tb_common'
// const { tools, validator } = TbCommon
Vue.prototype.$tools = tools
Vue.config.productionTip = false
Vue.prototype.$validator = validator

Vue.component('Pagination', Pagination)
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$Base64 = Base64
Vue.use(ElementUI);
Vue.use(vuescroll)
Vue.use(Components)
// Vue.use(TbCommon)
Vue.prototype.$JSEncrypt = JSEncrypt
// console.log(store)
store.dispatch("getDomain", { baseURL, name, url, acronym, codeLogin })
serviceWorker.unregister();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
