import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
// import Layout from '@/views/Layout'

// 公共路由
export const constantRoutes = [
  {
    path: "/",
    redirect: "/areastatistics",
    hidden: true,
    // component: Layout,
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/Login'], resolve),
    hidden: true
  },
  {
    path: '/logins',
    component: (resolve) => require(['@/views/Logins'], resolve),
    hidden: true
  },
  {
    path: '/singlelogin',
    component: (resolve) => require(['@/views/SingleLogin'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/404'], resolve),
    hidden: true
  },
  {
    path: '/maintain',
    name: 'maintain',
    component: (resolve) => require(['@/views/maintain'], resolve),
    hidden: true
  },
  {
    path: '/home',
    redirect: "/areastatistics",
    component: (resolve) => require(['@/views/Home'], resolve),
    children: [
      {
        path: '/areastatistics',
        name: 'areaStatistics',
        component: (resolve) => require(['@/views/AreaStatistics'], resolve)
      },
      {
        path: '/equipment',
        redirect: '/equipment',
        // name: 'Equipment',
        component: (resolve) => require(['@/views/Equipment'], resolve),
        children: [
          {
            path: '/equipment',
            name: 'equipment',
            component: (resolve) => require(['@/views/Equipment/equipment.vue'], resolve)
          },
          {
            path: 'offline',
            name: 'Offline',
            component: (resolve) => require(['@/views/Equipment/offLine.vue'], resolve)
          },
          {
            path: 'realtime',
            name: 'Realtime',
            component: (resolve) => require(['@/views/Equipment/realTime.vue'], resolve)
          },
          {
            path: 'parameter',
            name: 'Parameter',
            component: (resolve) => require(['@/views/Equipment/parameter.vue'], resolve)
          },
        ]
      },
      {
        path: '/warning',
        redirect: '/imagerecognition',
        // name: 'ImageRecognition',
        component: (resolve) => require(['@/views/ImageRecognition'], resolve),
        children: [
          {
            path: 'detail',
            name: 'imagerecognitionDetail',
            component: (resolve) => require(['@/views/ImageRecognition/detail.vue'], resolve)
          },
          {
            path: 'predetail',
            name: 'preDetail',
            component: (resolve) => require(['@/views/ImageRecognition/preDetail.vue'], resolve)
          },
          {
            path: '/imagerecognition',
            name: 'ImageRecognition',
            component: (resolve) => require(['@/views/ImageRecognition/list.vue'], resolve)
          },
        ]
      },
      {
        path: '/decision',
        name: 'Decision',
        component: (resolve) => require(['@/views/Decision'], resolve)
      },
      {
        path: '/video',
        name: 'Video',
        component: (resolve) => require(['@/views/Video'], resolve)
      },
      {
        path: '/system',
        redirect: '/system',
        // name: 'Equipment',
        component: (resolve) => require(['@/views/System'], resolve),
        children: [
          {
            path: '/system',
            name: 'system',
            component: (resolve) => require(['@/views/System/systemList.vue'], resolve)
          },
          {
            path: 'addUnit',
            name: 'AddUnit',
            component: (resolve) => require(['@/views/System/unitAdd.vue'], resolve)
          },
          {
            path: 'addUser',
            name: 'AddUser',
            component: (resolve) => require(['@/views/System/userAdd.vue'], resolve)
          },
          {
            path: 'roommanagement',
            name: 'roomManagement',
            component: (resolve) => require(['@/views/System/roomManagement.vue'], resolve)
          },
          {
            path: 'parametersetting',
            name: 'parameterSetting',
            component: (resolve) => require(['@/views/System/parameterSetting.vue'], resolve)
          }
        ]
      }
    ]
  }
]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
